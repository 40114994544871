<template>
    <div class="w100 bg-fff pl-24 pr-24 pt-30 box wtm_height2 autobox">
        <seanhead></seanhead>
        <div class="signbox w100 dp-f fw-w">
            <signItems :datas="state.Notice" :type="2" :height="310"></signItems>
        </div>
        <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage" :page-size=8>
       </el-pagination>
    </div>
</template>
<script setup>
import { reactive, ref, unref,onMounted } from 'vue'
import seanhead from "./seanhead.vue"
import signItems from "@/views/sign/components/signItems.vue"
import {httpToken}from '@/utils/request'
import qs from 'qs'
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const state = reactive({
    // status 1 通过 2停用 3 待审核 4未通过 5 已使用
    Notice:[]
})
// 分页改变方法
const handleCurrentChange=(()=>{
currentPage.value=e
getdatalb(e)
})
let getdatalb=((val, searchjson=[])=> {
  loading.value=true
  let json= [
  { column: "seal.seal_type", type: 'le',value:'4' },
    { column: "seal.seal_type", type: 'ge',value:'2' }
  ];
  for (let i = 0; i < searchjson.length; i++) {
    json.push(searchjson[i]);
  }
  currentPage.value=val;
  state.datasearchjson=searchjson;
  httpToken({
    method: "post",
    url: '/seal/seal-admin/list',
    data: qs.stringify({
      page: val,
      rows:8,
      paramData:JSON.stringify(json)
    }),
  }).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if(val!==res.data.current){
      getdatalb(res.data.current,searchjson)
      return
    }
    totalPage.value = res.data.total;
    // totalofpages.value = res.data.pages;
    state.Notice=res.data.records
    loading.value=false;
  }).catch(()=>{
    loading.value=false;
  })
})


onMounted(()=>{
getdatalb(1)

})
</script>
<style lang="scss" scoped >
.box {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}
.signbox{
    min-height: 600px;
}
</style>